import React, {FC} from 'react';
import favicon from '../../images/favicon.png';
import Section from '../../components/section';
import {PageHero} from '../../components/page-hero';
import Layout from '../../components/layout/layout';
import plug from '../../images/integrations/plug.svg';
import './styles.scss';
import {IntegrationBotProps} from '../../types';
import IntegrationSteps from '../integrationStep';

const IntegrationBot: FC<IntegrationBotProps> = ({botLogo, botName}) => (
  <Layout>
    <PageHero
      title={`Hexowatch Integration with ${botName}`}
      subtitle={
        botName === 'Zapier'
          ? 'Get changes from Hexowatch directly to your environment using Zapier'
          : `Get notifications in ${botName} about monitored URLs by Hexowatch`
      }
    />
    <Section>
      <div className="col-xs-12 col-lg-12 d-flex justify-content-center">
        <img src={favicon} alt="Hexowatch" width={120} height={120} className="mr-2" />
        <img src={plug} alt="plug" width={60} height={60} className="ml-2 mr-2 mt_30" />
        <img src={botLogo} alt={botName} width={120} height={120} className="ml-2" />
      </div>

      <div className="col-md-12 pt_50">
        {(botName === 'Telegram' || botName === 'Slack' || botName === 'Discord' || botName === 'Microsoft Teams') && (
          <p className="f_size_16 text-center" style={{marginTop: 0, color: '#263b5e'}}>
            To connect {botName} to Hexowatch, be sure to have the {botName} application installed on your device before
            clicking on connect.
          </p>
        )}
        {(botName === 'Zapier' || botName === 'Pabbly Connect') && (
          <p className="f_size_16 text-center" style={{marginTop: 0, color: '#263b5e'}}>
            Make sure to have your API key copied from the Settings page
          </p>
        )}
        <div className="pl_20 pr_20 text-center justify-content-center">
          {botName !== 'Zapier' && botName !== 'Pabbly Connect' ? (
            <IntegrationSteps
              list={[
                {
                  text: (
                    <>
                      Log in at{' '}
                      <a
                        href={
                          global &&
                          global.window &&
                          global.window.location &&
                          global.window.location.host &&
                          global.window.location.host.includes('37.186.119.181')
                            ? 'http://37.186.119.181:3276'
                            : 'https://dash.hexowatch.com'
                        }
                        no-index
                      >
                        <b>dash.hexowatch.com</b>
                      </a>
                    </>
                  ),
                  key: 'Log in',
                },
                {text: 'Go to the settings page', key: 'Settings'},
                {
                  text: (
                    <>
                      Click <b>&#60;Connect with {botName}&#62;</b>
                    </>
                  ),
                  key: 'Click connect',
                },
                {
                  text:
                    botName === 'Slack'
                      ? 'Sign in to your workspace'
                      : botName === 'Telegram'
                      ? 'Open the Telegram desktop app'
                      : `Sign in to your ${botName} account`,
                  key: 'Sign in',
                },
                {
                  text:
                    botName === 'Discord'
                      ? 'Select the desired Server and Authorize'
                      : botName === 'Telegram'
                      ? 'Send message on the HexowatchBot channel'
                      : botName === 'Slack'
                      ? 'Select the desired channel to receive notifications'
                      : 'Go to the APPs',
                  key: 'Select',
                },
                {
                  text: botName === 'Microsoft Teams' ? 'Search for "Incoming Webhooks"' : '',
                  key: 'Search',
                },
                {
                  text: botName === 'Microsoft Teams' ? '"Add to a Team" and "Set up a connector"' : '',
                  key: 'Add',
                },
                {
                  text:
                    botName === 'Microsoft Teams'
                      ? 'Copy the generated Webhook URL and paste it under the webhook section in Hexowatch'
                      : '',
                  key: 'Copy',
                },
              ]}
            />
          ) : (
            <IntegrationSteps
              list={[
                {text: `Sign in to your ${botName} account`, key: 'Log in'},
                {
                  text:
                    botName === 'Zapier'
                      ? 'Create a new Zap and search for Hexowatch'
                      : 'Create a workflow and search for Hexowatch',
                  key: 'Create',
                },
                {
                  text: 'Trigger a New Change',
                  key: 'Event',
                },
                {
                  text:
                    botName === 'Zapier'
                      ? 'Connect to your Hexowatch account'
                      : 'Click on “Connect” to connect Hexowatch account',
                  key: 'Connect',
                },
                {
                  text:
                    botName === 'Zapier'
                      ? 'Enter your Hexowatch account email and paste your API key'
                      : 'Add new connection by typing your API key',
                  key: 'Add and Enter',
                },
                {
                  text:
                    botName === 'Zapier'
                      ? 'Press continue and set up a trigger by choosing a Monitored URL'
                      : 'Click on “Save & Send Test Request”',
                  key: 'Continue',
                },
                {
                  text:
                    botName === 'Pabbly Connect' ? (
                      <>
                        Log in at{' '}
                        <a
                          href={
                            global &&
                            global.window &&
                            global.window.location &&
                            global.window.location.host &&
                            global.window.location.host.includes('37.186.119.181')
                              ? 'http://37.186.119.181:3276'
                              : 'https://dash.hexowatch.com'
                          }
                          no-index
                        >
                          <b>dash.hexowatch.com</b>
                        </a>
                      </>
                    ) : (
                      ''
                    ),
                  key: 'Pabbly Log in',
                },
                {
                  text: botName === 'Pabbly Connect' ? 'Go to the settings page' : '',
                  key: 'Go',
                },
                {
                  text: botName === 'Pabbly Connect' ? 'Click on “Test” next to the Pabbly Connect' : '',
                  key: 'Test',
                },
              ]}
            />
          )}
        </div>
        <h3 className="f_size_24 f_600 mb_20 text-center t_color">Stay notified in {botName}</h3>
        {botName !== 'Zapier' && botName !== 'Pabbly Connect' && (
          <>
            {(botName === 'Discord' ||
              botName === 'Telegram' ||
              botName === 'Slack' ||
              botName === 'Microsoft Teams') && (
              <>
                Our {botName} integration allows you to combine your Hexowatch monitorings with {botName} enabling you
                to receive alerts via {botName} whenever changes are detected.
              </>
            )}
          </>
        )}{' '}
        {(botName === 'Pabbly Connect' || botName === 'Zapier') && (
          <>
            Our {botName} integration allows you to combine your Hexowatch monitorings with {botName} enabling you to
            send data on your monitors to 100s of compatible apps on autopilot.
          </>
        )}
        <p>
          Using our integration now you wouldn't miss any important change that happens with website. Hexowatch is your
          AI sidekick to monitor any website for visual, content, source code or data changes. Access archived
          snapshots, get change alerts and extract data from any website in minutes.
        </p>
      </div>
    </Section>
  </Layout>
);

export default IntegrationBot;
