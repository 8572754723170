import React from 'react';
import './styles.scss';
import StepDashed from '../../images/integrations/step-dashed.svg';

const IntegrationSteps = (props: any) => (
  <div className="pt_20 stepss_container">
    {props.list.map(
      (item: any, index: any) =>
        item.text && (
          <>
            {index !== 0 && <img src={StepDashed} alt="" />}
            <div key={item.key} className="step_container">
              <div className="number">{index + 1}</div>
              <p>{item.text}</p>
            </div>
          </>
        ),
    )}
  </div>
);
export default IntegrationSteps;
